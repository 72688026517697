/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Mutation, State } from 'vuex-class'
import { PaymentState } from '@/store/payments/types'
const namespace: string = 'payments';

export default class BalanceReplenishmentPage extends Vue {
	@State('payments') payments: PaymentState | undefined;
	@Action('fetchPay', {namespace}) fetchPay: any;
	@Mutation('setBalanceReplenishment', {namespace}) setBalanceReplenishment: any;

	inputValue: string = '';
	inputCustom: string = '';
	isAutoBalance: boolean = true;

	onSubmit() {
		if(this.inputValue !== 'custom'){
			this.fetchPay({
				pay: this.inputValue,
				autoBalance: this.isAutoBalance
			});
			this.setBalanceReplenishment(this.inputValue);
		} else if(this.inputValue === 'custom') {
			this.fetchPay({
				pay: this.inputCustom,
				autoBalance:  this.isAutoBalance
			});
			this.setBalanceReplenishment(this.inputCustom);
			this.inputCustom = '';
		}
		this.$router.push({ name: 'payment-method' }).then(r => r);
	}
}
